<template>
  <div
    class="px-0 py-0 flex flex-col"
    :class="{
      'shadow-sm-1 rounded-lg bg-white py-6 px-7 cursor-pointer items-center': page === 'funnel' && layout === 'reviews' && section !== 'details',
      'border-t-1 border-gray-400 bg-white pt-6 cursor-pointer items-center': page === 'funnel' && layout === 'reviews' && section === 'details'
    }"
  >
    <!-- Default Layout-->
    <div
      v-if="props.layout === 'default'"
      class="flex items-center justify-center gap-4 text-sm mb-6 last:mb-0 min-h-[1.75rem] cursor-pointer"
      @click="handleLink()"
    >
      <p class="hidden md:block">
        Our customers say
      </p>
      <p
        v-show="data && data.starsString"
        class="font-bold text-lg hidden md:block"
      >
        {{ data && data.starsString }}
      </p>

      <span v-if="!_isEmpty(data)">
        <NuxtImg
          :src="`https:${data && data.starsImage.url}`"
          :width="data && data.starsImage.width"
          :height="data && data.starsImage.height"
          :loading="props.imageLazyLoad ? 'lazy' : 'eager'"
          alt="trustpilot rate"
        />
      </span>

      <p class="hidden md:block">
        {{ data && data.trustScore || 0 }} out of 5 based on
        <strong>{{ data && data.numberOfReviews.total || 0 }} reviews</strong>
      </p>
      <NuxtImg
        src="/icons/trustpilot/trustpilot.svg"
        alt="trustpilot"
        width="82"
        height="20"
        :loading="props.imageLazyLoad ? 'lazy' : 'eager'"
        class="inline-block"
      />
    </div>

    <!-- Reviews Layout-->
    <div
      v-else-if="props.layout === 'reviews'"
      class="last:mb-0 cursor-pointer"
      @click="handleLink()"
    >
      <div
        class="flex font-bold min-h-[1.625rem] justify-center"
        :class="[
          {
            'mb-3': page === 'default',
            'mb-2': page === 'funnel'
          },
          ratingClass
        ]"
      >
        <span
          v-show="data && data.starsString"
          class="cursor-default leading-[1.625rem]"
          :class="{
            'text-2xl': page === 'default',
            'text-lg': page === 'funnel'
          }"
        >
          {{ data && data.starsString }}
        </span>

        <span v-if="!_isEmpty(data)">
          <NuxtImg
            :src="`https:${data && data.starsImage.url}`"
            :width="data && data.starsImage.width"
            :height="data && data.starsImage.height"
            :loading="props.imageLazyLoad ? 'lazy' : 'eager'"
            alt="Trustpilot rate"
            class="ml-4"
          />
        </span>
      </div>

      <div class="flex min-h-[1.25rem]">
        <span
          class="inline-block whitespace-nowrap"
          :class="{
            'font-bold text-xs/[1.25rem]': page === 'funnel'
          }"
        >
          Based on
          <span class="underline font-bold inline-block">
            {{ data && data.numberOfReviews.total }} reviews
          </span> on
        </span>

        <NuxtImg
          src="/icons/trustpilot/trustpilot.svg"
          alt="trustpilot"
          width="82"
          height="20"
          class="inline-block ml-2"
          loading="lazy"
        />
      </div>
    </div>

    <!-- Compact Layout-->
    <div
      v-if="props.layout === 'compact'"
      class="flex items-center justify-center gap-4 text-sm mb-6 last:mb-0 min-h-[1.75rem] cursor-pointer"
      @click="handleLink()"
    >
      <span v-if="!_isEmpty(data)">
        <NuxtImg
          :src="`https:${data && data.starsImage.url}`"
          :width="data && data.starsImage.width"
          :height="data && data.starsImage.height"
          :loading="props.imageLazyLoad ? 'lazy' : 'eager'"
          alt="trustpilot rate"
        />
      </span>

      <NuxtImg
        src="/icons/trustpilot/trustpilot.svg"
        alt="trustpilot"
        width="82"
        height="20"
        :loading="props.imageLazyLoad ? 'lazy' : 'eager'"
        class="inline-block"
      />
    </div>
    
    <!-- Compact Reviews Layout-->
    <div
      v-else-if="props.layout === 'compact-reviews'"
      class="last:mb-0 cursor-pointer"
      @click="handleLink()"
    >
      <div
        class="flex flex-wrap font-bold min-h-6 justify-between items-center"
        :class="ratingClass"
      >
        <div class="flex items-center">
          <div
            v-show="data && data.starsString"
            class="cursor-default text-sm leading-6"
          >
            {{ data && data.starsString }}
          </div>

          <div v-if="!_isEmpty(data)" >
            <NuxtImg
              :src="`https:${data && data.starsImage.url}`"
              :width="data && data.starsImage.width"
              :height="data && data.starsImage.height"
              :loading="props.imageLazyLoad ? 'lazy' : 'eager'"
              alt="Trustpilot rate"
              class="ml-2 md:ml-3 max-h-[1.125rem] max-w-[6.125rem]"
            />
          </div>
        </div>

        <div
          class="ml-2 md:ml-3 text-sm font-normal"
        >
          Based on
          <span class="underline font-bold inline-block">
            {{ data && data.numberOfReviews.total }} reviews
          </span>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
import _isEmpty from 'underscore/cjs/isEmpty.js'

const trustpilotStore = useTrustpilotStore()

defineOptions({
  name: 'MoleculesTrustpilot'
})

const props = defineProps({
  layout: {
    type: String,
    default: 'default',
    validator: value => {
      const arr = ['default', 'reviews', 'compact', 'compact-reviews']

      if (Array.isArray(value)) {
        return value.filter(e => arr.includes(e))
      }

      return arr.includes(value)
    }
  },

  page: {
    type: String,
    default: 'default',
    validator: value => ['default', 'funnel'].includes(value)
  },

  section: {
    type: String,
    default: '',
    validator: value => ['', 'details'].includes(value)
  },

  imageLazyLoad: {
    type: Boolean,
    default: false
  },

  ratingClass: {
    type: String,
    default: ''
  }
})

// Fetch the Trustpilot data from store
const data = computed(() => trustpilotStore.trustpilotData)

function handleLink () {
  if (!data.value?.profileUrl) {
    // eslint-disable-next-line no-console
    return console.error('profileUrl is required!')
  }

  return window.open(data.value.profileUrl, '_blank')
}
</script>
